import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveProducts } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Container, LoadingSpinner, Masonry, useIntl, ContentrArea } from '@ttstr/components';

const ProductListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveProducts();
  }, [language]);

  return (
    <article className="product-listing list-page my-5">
      <Helmet>
        <title>{t(`PRODUCTS.TITLE`)}</title>
        <body className="product-listing-page" />
      </Helmet>

      <Container className="stage d-flex mb-5 mt-5 justify-content-center align-items-center">
        <img
          src={require('./assets/images/gh_logo_sub800.svg')}
          alt="GEMISCHTES HACK Merchandise"
          className="img-fluid"
        />
      </Container>

      <Container>
        <ContentrArea id="before-listing" />
        {/* <h1 className="text-center mb-4">{t(`PRODUCTS.TITLE`)}</h1> */}
      </Container>

      <Container>
        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products}
            filters="off"
            columnClassName="col-12 col-md-4 col-lg-4 random-rotation scribble-hover-overlay"
            itemClassName="random-rotation-object"
            showSubtitle
          />
        )}
      </Container>

      <Container>
        <ContentrArea id="after-listing" />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
